import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

switch (window.location.hostname) {
    case 'localhost':
        window.QUIZ_API_ROOT = 'https://apiquizqabilettest.elumiti.kz'
        window.API_ROOT = 'https://apiqabilettest.elumiti.kz'
        window.QUIZ_ROOT = 'http://localhost:8081'
        window.API_NEWS = 'https://elumiti.kz'
        break;
    case 'qabilettest.elumiti.kz':
        window.QUIZ_API_ROOT = 'https://apiquizqabilettest.elumiti.kz'
        window.API_ROOT = 'https://apiqabilettest.elumiti.kz'
        window.QUIZ_ROOT = 'https://quizqabilettest.elumiti.kz'
        window.API_NEWS = 'https://elumiti.kz'
        break;
    default:
        window.QUIZ_API_ROOT = 'https://apiquizqabilet.elumiti.kz'
        window.API_ROOT = 'https://apiqabilet.elumiti.kz'
        window.QUIZ_ROOT = 'https://quizqabilet.elumiti.kz'
        window.API_NEWS = 'https://elumiti.kz'
}

Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}
Vue.http.options.root = API_ROOT

Vue.http.options.progress = function (event) {
    if (event.lengthComputable) {
        NProgress.set(event.loaded / event.total)
    }
}

Vue.http.interceptors.push((request, next) => {
    NProgress.start()
    if (localStorage.getItem('role')) {
        request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('role'))
    }
    if (localStorage.getItem('token')) {
        request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    }
    next((response) => {
        NProgress.done()
        if (response.status == 401) {
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
        }
        if (response.status == 422) {
            Vue.toastr({
                message: 'Error',
                description: (Object.values(response.body.errors)[0][0]) ? Object.values(response.body.errors)[0][0] : response.body.message,
                type: 'error'
            })
        } else if (response.status >= 400) {
            Vue.toastr({
                message: 'Error',
                description: (response.body.message) ? response.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
                type: 'error'
            })
        }
        return response
    })
})

export const GetIIN = Vue.resource(API_ROOT + '/api/iin')

export const Auth = Vue.resource(API_ROOT + '/api/login')

export const GetIINByEmail = Vue.resource(API_ROOT + '/api/iin-by-email')

export const Logout = Vue.resource(API_ROOT + '/api/logout')

export const SendSMS = Vue.resource(API_ROOT + '/api/sms')

export const Api = API_ROOT